//this report component is for websiteScripts
<template>
    <div>
        <a @click="viewDetails" v-if="data.data.length > 1" style="cursor: pointer; color: blue">{{ data.error}}</a>
        <a v-else style="cursor: pointer; color: black">{{ data.error }}</a>
        <b-modal
            v-model="modalShow"
            id="modal-xl"
            size="xl"
            :title="'Website Scripts Not Loading (Sub-report)'"
        >
            <div class="row">
                <div class="col-12 col-lg-12 pt-3">
                    <ejs-grid
                        ref="grid"
                        id="Grids"
                        :dataSource="data.data"
                        :allowPaging="true"
                        :allowExcelExport='true'
                        :pageSettings='pageSettings'
                        :filterSettings="filterSettings"
                        :allowSorting ='true'
                        :allowFiltering="true"
                        :allowGrouping="true"
                    >
                        <e-columns>

                            <e-column field='date' headerText='Date'></e-column>
                            <e-column field='domain' headerText='Domain'></e-column>
                            <e-column field='scripts.url.hostname' headerText='Script'></e-column>
                            <e-column field='scripts.status' headerText='Errors'></e-column>
                        </e-columns>

                        <e-aggregates>
                            <e-aggregate>
                                <e-columns>
                                    <e-column columnName="scripts.status" type="Custom" :customAggregate="customAggregateFn" :footerTemplate='footerTemp'></e-column>
                                </e-columns>
                            </e-aggregate>
                        </e-aggregates>

                    </ejs-grid>
                </div>
            </div>

        </b-modal>
    </div>
</template>

<script>
import Vue from 'vue';

/*import VSelect from 'vue-select'*/
import {GridPlugin, Page, Sort, ExcelExport, Aggregate, Filter, Group} from "@syncfusion/ej2-vue-grids";


Vue.use(GridPlugin);

export default {
    name: "websiteError",
    components: {},

    provide: {
        grid: [Page, Sort, ExcelExport, Aggregate, Filter, Group, ]
    },
    // props:['summary'],
    data: function() {
        return {
            counter: this.details,
            counterCheck: 0,
            counerCheckTwo:1,
            data: {},
            repo:[],
            report:[],
            reports:[],
            proxieDataSet: [],
            filter:{
                to: '',
                from: '',
                status:'' ,
                provider:'',
                page:'',

            },
            modalTitle:"Modal Title",
            modalShow:false,
            pageSettings: {pageSize: 25},
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            footerTemp: function () {
                return  { template : Vue.component('footerTemplate', {
                        template: `<span>Error Count: {{data.Custom}}</span>`,
                        data () {return { data: {}};}
                    })
                }},



        }
    },
    methods:{
        viewDetails: function(){
            this.modalTitle = `Report Details (${this.data.name})`;
            this.modalShow = true;
        },
        customAggregateFn : function (data) {
            return data.result.filter((item) => item.scripts).length;
        }

    },

}
</script>

<style scoped>
/*@media (min-width: 576px){*/
/*    .modal-dialog {*/
/*        max-width: 614px;*/
/*        margin: 1.75rem auto;*/
/*    }   */
/*}*/


</style>
