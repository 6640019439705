<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3">
            <i class="fa fa-bullseye"></i> Website Scripts Not Loading
        </h2>
        <div class="mt-4 bg-white p-4">
            <div class="mt-4 pb-4 ">
                <div class="mb-4">
                    <div class="row">
                        <div class="col-lg-2 col-md-3">
                            <h6>Start Date-Time</h6>
                            <div class="wrap">
                                <ejs-datetimepicker :strictMode="true" :placehoder="'Select Start Date and Time'" v-model="filters.start_date" ></ejs-datetimepicker>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <h6>Stop Date-Time</h6>
                            <div class="wrap">
                                <ejs-datetimepicker  :strictMode="true" :min="filters.start_date" :placehoder="'Select Stop Date and Time'" v-model="filters.stop_date" ></ejs-datetimepicker>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <h6>Minimum Impression</h6>
                            <div class="wrap">
                                <input class="e-input mt-1" name='input' type="number" v-model="filters.minimumImpressions" placeholder="Enter Number"/>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <h6>Minimum % Failed to load</h6>
                            <div class="wrap">
                                <input class="e-input mt-1" name='input' type="number" v-model="filters.impressions" placeholder="Enter Number"/>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <h6>Domain</h6>
                            <div class="wrap">
                                <input class="e-input pt-2" name='input' type="text" v-model="filters.domain" placeholder="Enter Domain"/>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-3">
                            <div class="row">
                                <h6 class="ml-3">Tracking Script</h6>  <span
                                class="spinner-border spinner-border-sm  ml-2"
                                role="status"
                                aria-hidden="true"
                                v-if="sLoading"
                            ></span>
                            </div>

                        <div class="wrap">
                            <ejs-multiselect id='multiselect' :dataSource='options' v-model="filters.scripts"  placeholder="Find Scripts"></ejs-multiselect>
<!--                            <multiselect v-model="value" :options="options" :multiple="true"></multiselect>-->
                        </div>
                        </div>


                        <div class="col-lg-2 col-md-2">
                            <div class="tabs-wrap" style="padding-top: 34px;">
                                <button class="e-control e-progress-btn e-lib e-btn e-spin-right" data-ripple="true" aria-label="SEARCH progress" @click="search" style="color: white; background-color: rgb(56, 56, 56);" >
                                    <span class="e-btn-content">SEARCH</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <ejs-grid ref="grid"  :dataSource="data" :allowPaging="true" :allowResizing="true" :toolbar='toolbar' :toolbarClick='toolbarClick' :allowFiltering="true"
                      :allowExcelExport="true" :allowPdfExport="true" :excelExportComplete='exportComplete'  :pdfExportComplete='exportComplete' :allowSorting="true" :pageSettings="pageSettings"
                      :filterSettings="filterSettings">
                <e-columns>

                    <e-column field='date' minWidth='8' width='50' headerText="Date"></e-column>
                    <e-column field='domain' minWidth='8' width='50' headerText="Domain"></e-column>
                    <e-column field='script' headerText='Script'  minWidth='8' width='50'></e-column>
                    <e-column field='numberOfImpression' headerText='Number Of Impressions'  minWidth='8' width='50'></e-column>
                    <e-column field='failedimpressions' headerText='Number Of Fails'  minWidth='8' width='50'></e-column>
                    <e-column field='impressionsPercentage' headerText='Percentage Of Fails' format='N'   minWidth='8' width='50'></e-column>
                    <e-column field='error' headerText='Errors List'  minWidth='8' width='50' :template="template"></e-column>

                </e-columns>



                <e-aggregates>
                    <e-aggregate>
                        <e-columns>
                            <e-column type="Sum" field="failedimpressions" format="N" :footerTemplate='footerSum'></e-column>
                            <e-column type="Sum" field="numberOfImpression" format="N" :footerTemplate='footerSum'></e-column>
                        </e-columns>
                    </e-aggregate>
                </e-aggregates>


            </ejs-grid>

        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import axios from "axios";
import { DateTimePickerPlugin } from '@syncfusion/ej2-vue-calendars';
import { GridPlugin, PdfExport, ExcelExport, Toolbar, Aggregate,Sort, Filter,Page,Group } from '@syncfusion/ej2-vue-grids';
import {ClickEventArgs} from "@syncfusion/ej2-vue-navigations";
import swal from "sweetalert";
import moment from "moment-timezone";
import { MultiSelectPlugin } from "@syncfusion/ej2-vue-dropdowns";
// import Multiselect from 'vue-multiselect'
import websiteError from "@/components/reports/websiteError";

Vue.use(GridPlugin);
Vue.use(DateTimePickerPlugin);
Vue.use(MultiSelectPlugin);
export default {
    name: "websiteScripts.js",
    // components: { Multiselect },

    data() {
        return {

            filters: {
                start_date: new Date( this.filterTime().startDate),
                stop_date: new Date( this.filterTime().endDate),
                scripts:'',
                domain:'',
                minimumImpressions:'10',
                impressions:'20'
            },
            sLoading: true,
            value: null,
            options: '',
            dateFormat: { type: 'date', format: 'yyyy-MM-dd' },
            dateVal : new Date(new Date().setHours(0, 0, 0, 0)),
            toolbar: ['ExcelExport', 'PdfExport'],
            pageSettings: { pageSize: 50, pageSizes: true },
            filterSettings:{
                immediateModeDelay:500,
                mode:'Immediate'
            },
            data: [],
            groupOptions: {showDropArea: true, columns: [] },
            footerSum: function () {
                return  { template : Vue.component('sumTemplate', {
                        template: `<span>Total: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            numberOfImpression: function () {
                return  { template : Vue.component('numberOfImpression', {
                        template: `<span>Sum: {{data.Sum}}</span>`,
                        data () {return { data: {}};}
                    })
                }
            },
            template: function () {
                return {
                    template : websiteError
                }
            },


            count:0,
            columns: {},
            answers: [{text: 'No', value: 'no'}, {text: 'Yes', value: 'yes'}],
            city_fields: { text: 'city_name' },
        }
    },
    provide: {
        grid: [ PdfExport, ExcelExport, Toolbar, Aggregate,Sort,Filter,Page,Group],
    },
    created() {
        this.$root.preloader = false;
        this.getScriptList();
        this.options = this.$store.state.settings.exclusions.split(',')
    },
    methods: {
        // covertToArray(){
        //     let list = this.$store.state.settings.exclusions
        //     return list.split(',')
        // },
        filterTime(){
            let datefilter = {
                startDate : moment().subtract(1, 'days').startOf('day'),
                endDate : moment().subtract(1, 'days').endOf('day')
            }
            console.log(datefilter)
        return datefilter
        },
        toolbarClick(args = ClickEventArgs) {
            this.$refs.grid.showSpinner();
            switch (args.item.text) {
                /* tslint:disable */
                case 'Excel Export':
                    (this.$refs.grid).excelExport(this.getExcelExportProperties());
                    break;
                /* tslint:enable */
                case 'PDF Export':
                    (this.$refs.grid).pdfExport(this.getPdfExportProperties());
                    break;
            }
        },
        getExcelExportProperties() {
            return {
                fileName: "scriptsNotLoadingExport.xlsx"
            };
        },
        getPdfExportProperties() {
            return {
                fileName: "scriptsNotLoadingExport.pdf",
                pageOrientation: 'Landscape',
                pageSize: 'Legal'
            };
        },
        exportComplete() {
            this.$refs.grid.hideSpinner();
        },
        getScriptList: function(){
            //Load the scripts
            var request = {'apikey': this.$root.apikey};
            return axios.get(`${this.$root.serverUrl}/admin/settingsScripts`, {params:request}).then(function(resp){
                this.options = (resp.data && resp.data.data)? resp.data.data : [];
                this.sLoading = false
            }.bind(this));
        },
        search() {
            swal({title:'Please wait', text: `This report will take some time to load`, icon: 'info',timer: 7000})
            this.$refs.grid.showSpinner();
            var request = Object.assign({ apikey: this.$root.apikey }, this.filters);
            axios.get(`${this.$root.serverUrl}/admin/reports/websiteScripts`, {
                params: request,  json: true, }).then((resp) => {
                // if(resp) this.data = resp.data
                if(resp.data.error) {
                    this.reportLoading = false;
                    swal({title: 'Oops', text: `error failed to access data`, icon: 'error'})
                    this.$refs.grid.hideSpinner();
                }
                else {
                    this.data = resp.data.data
                    this.$refs.grid.hideSpinner();
                    swal({title:'Completed', text: `Data is now ready `, icon: 'success',timer: 3000})
                    // this.data.push({total:resp.data?.Count})
                    this.reportLoading = false;
                    this.showExportButton = true;
                }

                console.log(resp.data);
            })
        }
    }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>

</style>
